
































































import { Vue, Component } from 'vue-property-decorator'
import AboutUsContent from '@/components/layout/AboutUsContent.vue'
import VerticalHeading from '@/components/typography/VerticalHeading.vue'
import SignUpBanner from '@/components/layout/SignUpBanner.vue'
import Config from '@/config'
import PackageCheckoutQueries from '../../../../tradingmate-modules/src/models/common/PackageCheckoutQueries'
import FlexGroup from '@/components/layout/FlexGroup.vue'

@Component({
  components: {
    FlexGroup,
    AboutUsContent,
    VerticalHeading,
    SignUpBanner
  }
})

export default class OurWebsites extends Vue {
  onGetAWebsiteClick () : void {
    const url = Config.WebsiteUrls.Console + '/account/register' + '?p=' + PackageCheckoutQueries.Website
    window.open(url, '_self')
  }
}

